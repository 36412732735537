<template>
  <div id="pay-list">
    <van-nav-bar title="提现记录" left-arrow @click-left="goLastPage" />
    <loading :type=2 v-if="loading_show" />
    <div v-for="(item,index) in recordListArr" :key="index" @click="goSubPage('withdraw-detail',item.id)">
      <record-list :recordListObj="item" listType="withdraw" />
    </div>
  </div>
</template>
<script>
import RecordList from "@/components/order/RecordList.vue";
export default {
  data() {
    return {
      recordListArr: [],
      loading_show: false,
    };
  },
  components: {
    RecordList,
  },
  created() {
    this.getRecordList();
  },
  methods: {
    getRecordList() {
      this.loading_show = true;
      this.$http
        .get("/api/v2/worker/withdraw")
        .then((res) => {
          console.log(res);
          this.recordListArr = res.data.record;
          this.loading_show = false;
        })
        .catch((err) => {
          this.$toast(err.error);
          this.loading_show = false;
        });
    },
  },
};
</script>
<style>
</style>