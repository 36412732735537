<template>
  <div class="record_list">
    <div class="left public">
      <svg class="icon iconReset" aria-hidden="true">
        <use xlink:href="#icon-weixinzhifu1" v-if="recordListObj.method == 1"></use>
        <use xlink:href="#icon-zhifubao2" v-if="recordListObj.method == 2"></use>
        <use xlink:href="#icon--" v-if="recordListObj.method == 3"></use>
        <use xlink:href="#icon-wodedingdan" v-if="recordListObj.source == 1 || recordListObj.source == 2"></use>

      </svg>
    </div>
    <div class="center" v-if="listType == 'security'">
      <p v-if="recordListObj.direction == 1">保证金充值</p>
      <p v-if="recordListObj.direction == 2">保证金退还</p>
      <!-- <p v-if="recordListObj.method == 2">支付宝支付</p> -->
      <!-- <p v-if="recordListObj.method == 1">微信支付</p> -->
      <p>{{recordListObj.paid_at}}</p>
    </div>
    <div class="center" v-if="listType == 'withdraw'">
      <!-- <p class="centerPublic">{{recordListObj.out_trade_no}}</p> -->
      <p class="centerPublic1">{{methods[recordListObj.method - 1]}}</p>
      <p class="centerPublic" :style="`color:${status[recordListObj.status].color}` ">{{status[recordListObj.status].title}}</p>
      <p v-if="recordListObj.denial_reason" class="centerPublic" :style="`color:orange` ">{{recordListObj.denial_reason}} </p>
      <p class="centerPublic">{{recordListObj.created_at}}</p>
    </div>
    <div class="center" v-if="listType == 'bill'">
      <p class="centerPublic">无数据，测试用</p>
      <p class="centerPublic">{{recordListObj.created_at}}</p>
    </div>
    <div class="right public" v-if="listType == 'bill'">
      <p>
        <span :style="`color: ${direction[recordListObj.direction].color}` ">{{direction[recordListObj.direction].symbol}}{{recordListObj.amount}}元</span>
      </p>
    </div>
    <div class="right public" v-else>
      <p>
        <span>-{{recordListObj.amount}}元</span>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    listType: {
      type: String,
      default: "",
    },
    recordListObj: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      methods: ["微信提现", "支付宝提现", "银行卡提现"],
      status: {
        0: {
          icon: "#icon--shalou",
          title: "提现申请已提交",
          color: "",
        },
        1: {
          icon: "icon-yinhangqia-",
          title: "提现申请通过",
          color: "#239F3D",
        },
        2: {
          icon: "#icon-chenggong",
          title: "已到账",
          color: "#239F3D",
        },
        "-1": {
          icon: "#icon-shibai",
          title: "审核不通过",
          color: "red",
        },
        "-2": {
          icon: "#icon-shibai",
          title: "已撤销",
          color: "red",
        },
        "-3": {
          icon: "#icon-shibai",
          title: "提现失败，资金已原路",
          reason: "",
          color: "red",
        },
      },
      direction: {
        1: {
          color: "green",
          symbol: "+",
        },
        2: {
          color: "red",
          symbol: "-",
        },
      },
    };
  },
  methods: {},
};
</script>

<style lang="less">
.record_list {
  display: flex;
  text-align: left;
  background: #fff;
  margin: 1rem 0;
  box-sizing: border-box;
  padding: 1rem 0.2rem;
  .public {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .left {
    flex: 1;
    .iconReset {
      width: 3rem;
      height: 3rem;
    }
  }
  .center {
    flex: 2;
    p {
      padding: 0.1rem 0;
    }
    .centerPublic {
      font-size: 1.2rem;
      color: @themeFontColor;
    }
    .centerPublic1 {
      font-size: 1.3rem;
    }
    .centerPublic2 {
      font-size: 1.28rem;
      color: @themeFontColor;
      padding: 0.4rem 0;
    }
    .centerPublic3 {
      font-size: 1.2rem;
      color: @themeFontColor;
    }
  }
  .right {
    flex: 2;
    font-size: 1.4rem;
    font-weight: 600;
    color: salmon;
  }
}
</style>